import React from 'react';
import StyledSalonText from '../atoms/SalonText';

interface Days {
  maandag: string;
  dinsdag: string;
  woensdag: string;
  donderdag: string;
  vrijdag: string;
  zaterdag: string;
  zondag: string;
  id: string;
}

interface Props {
  daysOfWeek: Days[];
}

const SalonOpenHoursList: React.FC<Props> = ({ daysOfWeek }) => (
  <>
    {[...daysOfWeek].map(
      ({
        id,
        maandag,
        dinsdag,
        woensdag,
        donderdag,
        vrijdag,
        zaterdag,
        zondag,
      }) => (
        <div key={id}>
          <StyledSalonText>Maandag : {maandag}</StyledSalonText>
          <StyledSalonText>Dinsdag : {dinsdag}</StyledSalonText>
          <StyledSalonText>Woensdag : {woensdag}</StyledSalonText>
          <StyledSalonText>Donderdag : {donderdag}</StyledSalonText>
          <StyledSalonText>Vrijdag : {vrijdag}</StyledSalonText>
          <StyledSalonText>Zaterdag : {zaterdag}</StyledSalonText>
          <StyledSalonText>Zondag : {zondag}</StyledSalonText>
        </div>
      )
    )}
  </>
);

export default SalonOpenHoursList;
