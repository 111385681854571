import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import Logo from '../atoms/Logo';
import Dropdown from '../molecules/Dropdown';
import SalonInfo from './../molecules/SalonInfo';
import { onClickOutside } from './../../assets/hooks/hooks';
import FlagIcon from '../../assets/flag.inline.svg';

import {
  FaHome,
  FaTag,
  FaEuroSign,
  FaImage,
  FaPaperPlane,
  FaFont,
} from 'react-icons/fa';

const MenuWrapper = styled.div`
  position: absolute;
  min-height: 100%;
  width: 100%;
  z-index: 999;
  @media (min-width: ${({ theme }) =>
      theme.breakpoints.s}) and (max-width: 1000px) {
    width: 49rem;
  }
  background-color: #f2f2f2;
  font-size: ${({ theme }) => theme.font.size.normal};
  transform: ${({ isOpen }) =>
    isOpen ? 'translateX(0)' : 'translateX(-100%)'};
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    z-index: auto;
    transform: none;
    background-color: transparent;
    min-height: auto;
  }
`;

const DisplayWrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    display: none;
  }
`;

const StyledList = styled.ul`
  height: 100%;
  max-width: 100%;
  margin-bottom: 11rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
    margin-left: 13rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    margin: 0 auto 11rem;
  }
  list-style: none;
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    flex-direction: row;
    width: auto;
    max-width: 1140px;
    margin-top: 15rem;
    font-size: ${({ theme }) => theme.font.size.bigger};
    li {
      padding: 0 1.5rem;
    }
    li:nth-child(3) {
      margin-right: auto;
    }
  }
`;

const SalonInfoWrapper = styled.div`
  width: 23.5rem;
  height: 27.2rem;
  margin: 5rem auto 4rem;
`;

const StyledListItem = styled.li`
  width: 23rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
    width: 36rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    width: auto;
    margin: 0.8rem;
  }
  max-width: 100%;
  margin: 0.8rem;
  margin-left: auto;
  margin-right: auto;
  color: ${({ theme }) => theme.font.color.main};
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.font.family.bold};
  font-weight: 600;
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.font.color.accent};
  }
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.font.color.main};
    :hover {
      color: ${({ theme }) => theme.font.color.accent};
    }
  }
  svg {
    font-size: ${({ theme }) => theme.font.size.normal};
    color: ${({ theme }) => theme.font.color.secondary};

    margin: 0 1.5rem;
    @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
      display: none;
    }
    :hover {
      color: ${({ theme }) => theme.font.color.accent};
    }
  }
  .active::after {
    position: absolute;
    content: '';
    height: 0.3rem;
    width: 5.4rem;
    display: block;
    background-color: ${({ theme }) => theme.font.color.accent};
    margin-left: 5.25rem;
    @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
      margin-left: 0;
      height: 0.4rem;
    }
  }
  .active::after {
    position: absolute;
    content: '';
    height: 0.3rem;
    width: 5.4rem;
    display: block;
    background-color: #fe0000;
    margin-left: 5.25rem;
    @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
      margin-left: 0;
      height: 0.4rem;
    }
  }
`;

const PLflag = styled(FlagIcon)`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  width: 4.5rem;
  height: 4.5rem;
  border: 0.1rem solid ${({ theme }) => theme.font.color.secondary};
  border-radius: 50%;
`;

const Menu = ({ isOpen, closeMenu }) => {
  const [isDropdown, setDropdown] = useState(false);
  const toggleDropdown = () => setDropdown(!isDropdown);
  const node = useRef();
  onClickOutside(node, () => setDropdown(false));
  return (
    <MenuWrapper isOpen={isOpen}>
      <DisplayWrapper>
        <Link to="/" onClick={closeMenu}>
          <Logo />
        </Link>
        <Link to="/pl" onClick={closeMenu}>
          <PLflag />
        </Link>
        <SalonInfoWrapper>
          <SalonInfo />
        </SalonInfoWrapper>
      </DisplayWrapper>
      <StyledList>
        <StyledListItem>
          <Link to="/trimsalon" onClick={closeMenu} activeClassName="active">
            <FaHome />
            trimsalon
          </Link>
        </StyledListItem>
        <StyledListItem ref={node} onClick={toggleDropdown}>
          <FaTag />
          offerte{isDropdown ? <FaChevronUp /> : <FaChevronDown />}
          {isDropdown && <Dropdown closeMenu={closeMenu} />}
        </StyledListItem>
        <StyledListItem>
          <Link to="/prijslijst" onClick={closeMenu} activeClassName="active">
            <FaEuroSign />
            prijslijst
          </Link>
        </StyledListItem>
        <StyledListItem>
          <Link to="/blog" onClick={closeMenu} activeClassName="active">
            <FaFont />
            blog
          </Link>
        </StyledListItem>
        <StyledListItem>
          <Link to="/galerij" onClick={closeMenu} activeClassName="active">
            <FaImage />
            galerij
          </Link>
        </StyledListItem>
        <StyledListItem>
          <Link to="/contact" onClick={closeMenu} activeClassName="active">
            <FaPaperPlane />
            contact
          </Link>
        </StyledListItem>
      </StyledList>
    </MenuWrapper>
  );
};

export default Menu;
