import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Arrow from '../../assets/arrow.inline.svg';
import Marker from '../../assets/marker.inline.svg';
import Phone from '../../assets/phone.inline.svg';
import SalonOpenHoursList from './SalonOpenHoursList';
import SalonAddressInfo from './SalonAddressInfo';
import SalonPhoneNumber from './SalonPhoneNumber';

const StyledContainer = styled.div`
  width: 26rem;
  height: 30rem;
  padding: 2rem 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: column;
  margin-left: 3rem;
`;

const StyledTextWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  margin-bottom: 3rem;
`;

const StyledAddress = styled(StyledTextWrapper)`
  font-style: none;
`;

const StyledArrow = styled(Arrow)<{ top: string }>`
  position: absolute;
  top: ${props => props.top};
  left: -0.7rem;
`;

const StyledMarker = styled(Marker)`
  position: absolute;
  top: 0%;
  left: -1.2rem;
`;

const StyledPhone = styled(Phone)`
  position: absolute;
  top: -40%;
  left: -1.5rem;
`;

const query = graphql`
  {
    datoCmsSaloninfo {
      info {
        ... on DatoCmsOpenhour {
          id
          maandag
          dinsdag
          woensdag
          donderdag
          vrijdag
          zaterdag
          zondag
        }
        ... on DatoCmsAddress {
          id
          first
          second
        }
        ... on DatoCmsPhoneNumber {
          id
          phoneNumber
        }
      }
    }
  }
`;

const SalonInfo = () => {
  const [top, setTop] = useState<string>('0');
  const data = useStaticQuery(query);
  const salonInfo = data.datoCmsSaloninfo.info;
  const address = [salonInfo[0]];
  const daysOfWeek = [salonInfo[1]];
  const phoneNumber = [salonInfo[2]];

  const checkDayAndSetTop = (dayName: string) => {
    switch (dayName) {
      case 'Monday':
        return setTop('-5%');
      case 'Tuesday':
        return setTop('10%');
      case 'Wednesday':
        return setTop('25%');
      case 'Thursday':
        return setTop('40%');
      case 'Friday':
        return setTop('55%');
      case 'Saturday':
        return setTop('68%');
      case 'Sunday':
        return setTop('83%');
      default:
        return '0';
    }
  };

  useEffect(() => {
    const dayName = new Date().toLocaleString('en-us', { weekday: 'long' });
    checkDayAndSetTop(dayName);
  }, []);

  return (
    <StyledContainer>
      <StyledTextWrapper as={StyledAddress}>
        <StyledMarker />
        <SalonAddressInfo address={address} />
      </StyledTextWrapper>
      <StyledTextWrapper>
        <StyledPhone />
        <SalonPhoneNumber phoneNumber={phoneNumber} />
      </StyledTextWrapper>
      <StyledTextWrapper>
        <StyledArrow top={top} />
        <SalonOpenHoursList daysOfWeek={daysOfWeek} />
      </StyledTextWrapper>
    </StyledContainer>
  );
};

export default SalonInfo;
