export const theme = {
  font: {
    color: {
      main: '#333333',
      secondary: '#686868',
      accent: '#fe0000',
    },
    family: {
      main: `'Josefin Sans Light Roman', 'Josefin Sans Regular Roman', 'Josefin Sans', 'sans-serif'`,
      bold: `'Josefin Sans SemiBold Roman', 'Josefin Sans Regular Roman',
        'Josefin Sans', 'sans-serif'`,
      cursive: `'Lobster', cursive `,
      secondary: `'Arial Regular', 'Arial, sans-serif'`,
    },
    size: {
      normal: '1.5rem',
      bigger: '2rem',
      big: '2.5rem',
      superbig: '3.5rem',
    },
  },
  breakpoints: {
    xs: '500px',
    s: '600px',
    m: '1000px',
    l: '1400px',
    xl: '1600px',
    xxl: '1800px',
  },
  placeholder: '#f2f2f2',
  inputUnderline: ' #dfdfdf',
};
