import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { FaFacebookF } from 'react-icons/fa';

const FooterWrapper = styled.footer``;

const FooterLogo = styled.p`
  font-family: ${({ theme }) => theme.font.family.cursive};
  text-align: center;
  margin: 5rem auto 1.4rem;
  font-size: ${({ theme }) => theme.font.size.normal};
  letter-spacing: 0.3rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
    font-size: ${({ theme }) => theme.font.size.bigger};
    margin: 4rem auto 1.8rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    font-size: ${({ theme }) => theme.font.size.big};
    margin: 5rem auto 1.7rem;
  }
`;

const FooterText = styled.p`
  font-family: ${({ theme }) => theme.font.family.bold};
  font-weight: 600;
  color: ${({ theme }) => theme.font.main};
  text-align: center;
  margin: 1.1rem auto;
  font-size: 1rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
    font-size: ${({ theme }) => theme.font.size.normal};
    margin: 1.5rem auto;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    font-size: ${({ theme }) => theme.font.size.bigger};
    margin: 1.3rem auto;
  }
`;

const FooterFacebook = styled.a`
  display: block;
  color: ${({ theme }) => theme.font.color.main};
  text-align: center;
  margin: 2.3rem auto 5rem;
  font-size: ${({ theme }) => theme.font.size.big};
  @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
    font-size: 3rem;
    margin: 1.5rem auto 4.5rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    font-size: ${({ theme }) => theme.font.size.superbig};
    margin: 2.3rem auto 5rem;
  }
`;

const query = graphql`
  {
    datoCmsFooter {
      title
      copyright
      privacy
      facebook
    }
  }
`;

const Footer = () => {
  const data = useStaticQuery(query);
  const { title, copyright, privacy, facebook } = data.datoCmsFooter;
  return (
    <>
      <FooterWrapper>
        <FooterLogo>{title}</FooterLogo>
        <FooterText>{copyright}</FooterText>
        <FooterText>{privacy}</FooterText>
        <FooterFacebook href={facebook}>
          <FaFacebookF />
        </FooterFacebook>
      </FooterWrapper>
    </>
  );
};

export default Footer;
