import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import slugify from 'slugify';

const DropdownItems = [
  'Puppy behandeling',
  'Effileren',
  'Knippen',
  'Scheren',
  'Plukken',
  'Ontwollen',
  'Anti – verharing behandeling',
  'Knippen van nagels',
];

const StyledDropdown = styled.ul`
  list-style: disc;
  padding: 1rem 2rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    position: absolute;
    background-color: white;
    z-index: 111;
  }
`;

const StyledDropdownItem = styled.li`
  margin: 1.5rem;
  a {
    text-transform: capitalize;
    font-family: ${({ theme }) => theme.font.family.bold};
    font-weight: 600;
  }
`;

const DropdownItemList = DropdownItems.map(item => {
  const slugifiedItem = slugify(item, {
    lower: true,
  });
  return (
    <StyledDropdownItem>
      <Link to={`/bieden/${slugifiedItem}`} key={item}>
        {item}
      </Link>
    </StyledDropdownItem>
  );
});

const Dropdown = ({ closeMenu }) => (
  <StyledDropdown onClick={closeMenu}>{DropdownItemList}</StyledDropdown>
);

export default Dropdown;
