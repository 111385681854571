import styled from 'styled-components';

const StyledSalonText = styled.p`
  margin: 0 0 0.4rem 2rem;
  font-family: ${({ theme }) => theme.font.family.secondary};
  font-size: ${({ theme }) => theme.font.size.normal};
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    font-size: ${({ theme }) => theme.font.size.bigger};
  }
`;

export default StyledSalonText;
