import React from 'react';
import StyledSalonText from '../atoms/SalonText';

interface Address {
  first: string;
  second: string;
  id: string;
}

interface Props {
  address: Address[];
}

const SalonAddressInfo: React.FC<Props> = ({ address }) => (
  <>
    {[...address].map(({ id, first, second }) => (
      <div key={id}>
        <StyledSalonText>{first}</StyledSalonText>
        <StyledSalonText>{second}</StyledSalonText>
      </div>
    ))}
  </>
);

export default SalonAddressInfo;
