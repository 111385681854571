import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin:0;
    padding:0;
    outline:none;
  }


  button{
  border:none;
  background:none;
  cursor: pointer;
  }

  html {
    font-size: 10px;
  }

  body {
    width:100%;
    max-width:1920px;
    height:100vh;
    font-size: 1.6rem;
    font-family: ${({ theme }) =>
      theme.font.family.main}, comic-sans, sans-serif;
    color: ${({ theme }) => theme.font.color.main};
    position:relative;
    margin:0 auto;
    padding:0;
    outline:none;

  }

  a{
    text-decoration:none;
  }

  input[type="search"]::-webkit-search-cancel-button {
  display: none;
}
    *:focus {
    outline: transparent;
   }

   /* Debugger CSS */
/* * {  background-color: rgba(255, 0, 0, 0.2)}
* * {  background-color: rgba(0, 255, 0, 0.2)}
* * * {  background-color: rgba(0, 0, 255, 0.2)}
* * * * {  background-color: rgba(255, 0, 255, 0.2)}
* * * * * {  background-color: rgba(0, 255, 255, 0.2)}
* * * * * * {   background-color: rgba(255, 255, 0, 0.2)}
* * * * * * * {   background-color: rgba(255, 0, 0, 0.2)}
* * * * * * * * {   background-color: rgba(0, 255, 0, 0.2)}
* * * * * * * * * {   background-color: rgba(0, 0, 255, 0.2)} */
`;
export default GlobalStyle;
