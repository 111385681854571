import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';
import Helmet from 'react-helmet';

interface Props {
  title: string;
  description: string;
  image?: string;
}

interface Site {
  defaultTitle: string;
  defaultDescription: string;
  defaultImage: string;
  siteUrl: string;
  keywords: string;
  authorName: string;
}

interface SiteMeta {
  site: {
    siteMetadata: Site;
  };
}

const query = graphql`
  {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        keywords
        siteUrl
        author {
          authorName: name
        }
      }
    }
  }
`;

const SEO: React.FC<Props> = ({ title, description, image }) => {
  const {
    site: { siteMetadata },
  } = useStaticQuery<SiteMeta>(query);
  const { pathname } = useLocation();

  const {
    defaultTitle,
    defaultDescription,
    defaultImage,
    siteUrl,
    keywords,
    authorName,
  } = siteMetadata;

  const seoData = {
    seoTitle: title || defaultTitle,
    seoDescription: description || defaultDescription,
    seoImage: `${siteUrl}${image || defaultImage}`,
    seoUrl: `${siteUrl}${pathname}`,
  };

  const { seoTitle, seoDescription, seoImage, seoUrl } = seoData;

  return (
    <Helmet title={seoTitle}>
      <meta name="description" content={seoDescription} />
      <meta name="image" content={seoImage} />
      <meta property="og:url" content={seoUrl} />
      <meta property="og:title" content={seoTitle} />
      <meta property="og:description" content={seoDescription} />
      <meta property="og:image" content={seoImage} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content={authorName} />
    </Helmet>
  );
};

export default SEO;
