import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import {
  FaFacebookF,
  FaFacebookMessenger,
  FaWhatsapp,
  FaPhone,
} from 'react-icons/fa';

const PawImage = styled.img`
  height: auto;
  width: 100%;
`;

const PawWrapper = styled.div`
  top: 1.5rem;
  right: 1rem;
  width: 7.1rem;
  height: 9.4rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
    width: 9.3rem;
    height: 12.3rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    width: 13.8rem;
    height: 14.5rem;
    right: 17rem;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
`;

const PawText = styled.p`
  font-family: ${({ theme }) => theme.font.family.bold};
  font-weight: 600;
  font-size: 1rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
    font-size: ${({ theme }) => theme.font.size.normal};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    position: relative;
    top: -6.5rem;
    left: 11rem;
    z-index: -1;
  }
  text-align: center;
  color: ${({ theme }) => theme.font.color.secondary};
`;

const IconWrapper = styled.a`
  display: block;
  position: absolute;
  color: ${({ theme }) => theme.font.color.secondary};
  :hover {
    color: ${({ theme }) => theme.font.color.accent};
    cursor: pointer;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    font-size: ${({ theme }) => theme.font.size.bigger};
  }
`;

const FacebookWrapper = styled(IconWrapper)`
  top: 2rem;
  left: 0.5rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
    top: 2.7rem;
    left: 0.8rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    top: 5rem;
    left: 1.2rem;
  }
`;

const MessengerWrapper = styled(IconWrapper)`
  top: 0.2rem;
  left: 2.2rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
    top: 0.5rem;
    left: 3.2rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    top: 1.3rem;
    left: 4.7rem;
  }
`;

const WhatsappWrapper = styled(IconWrapper)`
  top: 0.6rem;
  left: 4.5rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
    top: 1rem;
    left: 6rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    top: 2.1rem;
    left: 8.9rem;
  }
`;

const PhoneWrapper = styled(IconWrapper)`
  top: 3rem;
  left: 5.2rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
    top: 4.4rem;
    left: 7.1rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    top: 7.1rem;
    left: 10.5rem;
    &:hover ~ p {
      color: ${({ theme }) => theme.font.color.accent};
      transform: translateX(0);
    }
  }
`;

export const query = graphql`
  {
    file(name: { eq: "paw" }) {
      childImageSharp {
        fluid(maxWidth: 145, maxHeight: 145, quality: 90) {
          src
          srcSet
          sizes
        }
      }
    }
    datoCmsSocial {
      facebook
      whatsapp
      tel
      messenger
    }
  }
`;

const Paw = () => {
  const data = useStaticQuery(query);
  const { src, srcSet, sizes } = data.file.childImageSharp.fluid;
  const { facebook, whatsapp, tel, messenger } = data.datoCmsSocial;
  return (
    <PawWrapper>
      <FacebookWrapper href={facebook}>
        <FaFacebookF />
      </FacebookWrapper>
      <MessengerWrapper href={messenger}>
        <FaFacebookMessenger />
      </MessengerWrapper>
      <WhatsappWrapper href={whatsapp}>
        <FaWhatsapp />
      </WhatsappWrapper>
      <PhoneWrapper>
        <FaPhone size={'0.8em'} />
      </PhoneWrapper>
      <PawImage src={src} srcSet={srcSet} sizes={sizes} />
      <PawText>{tel}</PawText>
    </PawWrapper>
  );
};

export default Paw;
