import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import styled, { ThemeProvider } from 'styled-components';
import Navigation from '../components/templates/Navigation';
import Footer from '../components/templates/Footer';
import GlobalStyles from '../themes/GlobalStyles';
import { theme } from '../themes/MainTheme';
import SalonInfo from '../components/molecules/SalonInfo';
import SEO from '../components/atoms/SEO';
import favicon from '../assets/images/favicon.ico';

const Modal = styled.div<{ open: boolean }>`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    display: ${({ open }) => (open ? 'block' : 'none')};
    position: fixed;
    right: 0;
    top: 45%;
  }
`;

const Wrapper = styled.div`
  display: block;
  position: relative;
`;

const MainLayout = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const location = useLocation();

  const currentPage = () => {
    const fullLocation = location.pathname.toString().substring(1);
    const slicedLocation =
      fullLocation.charAt(0).toUpperCase() + fullLocation.slice(1);
    return slicedLocation;
  };

  const titleTemplate = `${currentPage()} - Hondentrimsalon Happy Puppy`;

  useEffect(() => {
    location.pathname === '/contact' ? setModalOpen(false) : setModalOpen(true);
  }, [location.pathname]);

  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Josefin+Sans:400,600|Lobster|Montserrat:400,500,700&display=swap"
          rel="stylesheet"
        />
        <link rel="shortcut icon" href={favicon} type="image/x-icon" />
      </Helmet>
      <SEO title={titleTemplate} description={titleTemplate} />
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Navigation />
        <Wrapper>{children}</Wrapper>
        <Modal open={modalOpen}>
          <SalonInfo />
        </Modal>
        <Footer />
      </ThemeProvider>
    </>
  );
};

export default MainLayout;
