import React from 'react';
import styled from 'styled-components';

const StyledBurger = styled.button`
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    display: none;
  }
  position: absolute;
  width: 7.6rem;
  height: 7.6rem;
  top: 0.7rem;
  left: 1.6rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
    top: 2.6rem;
    left: 3.5rem;
  }
  padding: 2.4rem 1.9rem 3rem 1.4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;
  z-index: 1000;

  div {
    height: 0.4rem;
    background: ${({ isOpen }) => (isOpen ? '#0D0C1D' : '#fe0000')};
    border-radius: 1rem;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1.2rem;

    :first-child {
      width: ${({ isOpen }) => (isOpen ? '4.3rem' : '2.1rem')};
      transform: ${({ isOpen }) => (isOpen ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-child(2) {
      width: 4.3rem;
      opacity: ${({ isOpen }) => (isOpen ? '0' : '1')};
      transform: ${({ isOpen }) =>
        isOpen ? 'translateX(2rem)' : 'translateX(0)'};
    }

    :nth-child(3) {
      width: ${({ isOpen }) => (isOpen ? '4.3rem' : '2.9rem')};
      ${({ isOpen }) => (isOpen ? '0' : '1')};
      transform: ${({ isOpen }) => (isOpen ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;

const Burger = ({ isOpen, setOpen }) => {
  const toggleOpen = () => setOpen(!isOpen);
  return (
    <StyledBurger isOpen={isOpen} onClick={toggleOpen}>
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

export default Burger;
