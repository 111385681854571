import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

const LogoImage = styled.img`
  height: auto;
  width: 100%;
`;

const LogoWrapper = styled.div`
  width: 13.9rem;
  height: 13.9rem;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  z-index: 11;
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    height: 29rem;
    width: 29rem;
  }
`;

export const query = graphql`
  {
    file(name: { eq: "logo" }) {
      childImageSharp {
        fluid(maxWidth: 290, maxHeight: 290, quality: 90) {
          src
          srcSet
          sizes
        }
      }
    }
  }
`;

const Logo = () => {
  const data = useStaticQuery(query);
  const { src, srcSet, sizes } = data.file.childImageSharp.fluid;
  return (
    <LogoWrapper>
      <LogoImage src={src} srcSet={srcSet} sizes={sizes} />
    </LogoWrapper>
  );
};

export default Logo;
