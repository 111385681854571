import React from 'react';
import StyledSalonText from '../atoms/SalonText';

interface PhoneNumber {
  phoneNumber: string;
  id: string;
}

interface Props {
  phoneNumber: PhoneNumber[];
}

const SalonPhoneNumber: React.FC<Props> = ({ phoneNumber }) => (
  <>
    {[...phoneNumber].map(({ id, phoneNumber }) => (
      <div key={id}>
        <StyledSalonText>{phoneNumber}</StyledSalonText>
      </div>
    ))}
  </>
);

export default SalonPhoneNumber;
