import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Logo from './../atoms/Logo';
import Paw from './../atoms/Paw';
import Burger from './../atoms/Burger';
import Menu from './../Organisms/Menu';
import { onClickOutside } from './../../assets/hooks/hooks';
import FlagIcon from '../../assets/flag.inline.svg';

const NavigationWrapper = styled.nav`
  display: relative;
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    > a {
      overflow: hidden;
      display: block;
      overflow: hidden;
    }
    > a:before,
    > a:after {
      top: 19rem;
      z-index: -100;
      position: absolute;
      content: '';
      height: 0.2rem;

      display: inline-block;
      background-color: #eee;
    }
    > a:before {
      left: 0.5rem;
      right: 0.5rem;
    }
  }
`;

const PLflag = styled(FlagIcon)`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    display: block;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    width: 4.5rem;
    height: 4.5rem;
    border: 0.1rem solid ${({ theme }) => theme.font.color.secondary};
    border-radius: 50%;
  }
`;

const Navigation = () => {
  const [isOpen, setOpen] = useState(false);
  const closeMenu = () => setOpen(false);
  const node = useRef();
  onClickOutside(node, () => setOpen(false));
  return (
    <NavigationWrapper ref={node}>
      <Burger isOpen={isOpen} setOpen={setOpen} />
      <Menu isOpen={isOpen} closeMenu={closeMenu} />
      <Link to="/">
        <Logo />
      </Link>
      <Paw />
      <Link to="/pl">
        <PLflag />
      </Link>
    </NavigationWrapper>
  );
};

export default Navigation;
